import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"
import InquiryManagement from "../../customComponents/inquiryManagement"
import "./style.scss"

const index = () => {
  return (
    <div className="inquiry-management-page">
      <Topbar />
      <CustomLayout selectedPage={<InquiryManagement />} />
    </div>
  )
}

export default index
