import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import _ from "lodash"
import moment from "moment"
import { Table } from "antd"
import { itemRender } from "../../salesManagementy/Shared"
import { navigate } from "gatsby"
import { getAllConversation, getMyStore } from "../../../services/user"

const japaneseDateConvert = (string) => {
  const date = moment(string)
  return date.format("YYYY/MM/DD HH:mm")
}

const DataTable = () => {
  const pageSize = useMemo(() => 10, [])
  const [dataTable, setDataTable] = useState({
    data: [],
    total: 0,
  })
  const [myData, setMyData] = useState({
    account_id: "",
    pubsub_token: "",
    contact_identifier: "",
  })
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState({
    offset: 0,
    limit: pageSize,
  })

  const columns = [
    {
      title: "",
      width: 100,
      render: (_, data) => {
        return data.type === "new" ? (
          <span className="text-[11px] text-white bg-custom-1 px-1 mr-[5px] rounded-sm">
            NEW
          </span>
        ) : null
      },
    },
    {
      title: (
        <div className="text-center flex flex-col">
          <span>問い合わせ番号</span>
          <span>注文番号</span>
        </div>
      ),
      width: 150,
      dataIndex: "order_id",
      key: "order_id",
      render: (order_id, data) => {
        return (
          <div style={{ color: "#888888", textAlign: "center" }}>
            {order_id}
          </div>
        )
      },
    },
    {
      title: <div className="text-center">問合せ日時</div>,
      dataIndex: "created_at",
      key: "created_at",
      width: 140,
      render: (text) => (
        <div style={{ color: "#888888", textAlign: "center" }}>{text}</div>
      ),
    },
    {
      title: <div className="text-center">受取人</div>,
      width: 300,
      dataIndex: "customer",
      key: "customer",
      render: (text) => (
        <div style={{ color: "#222222", textAlign: "center" }}>{text}</div>
      ),
    },
    {
      title: <div className="text-center">メッセージ</div>,
      width: 140,
      dataIndex: "message",
      key: "message",
      render: (text) => (
        <div
          style={{ color: "#222222", textAlign: "center" }}
          className="line-clamp-1"
        >
          {text}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setLoading(true)
    getMyStore().then(({ data }) => {
      if (data) {
        localStorage.setItem("authority", data.stores.access_token)
        console.log(data)
        const custom = {
          account_id: data.stores.account_id,
          pubsub_token: data.stores.pubsub_token,
          contact_identifier: data.stores.contact_identifier,
        }
        setMyData({ ...custom })
        if (data.stores.account_id) {
          getAllConversation(data.stores.account_id, page).then(({ data }) => {
            console.log(data)
            const newData = data.result.data.payload.map((item) => ({
              ...custom,
              key: item.id,
              order_id: item.id,
              created_at: japaneseDateConvert(item.timestamp * 1000),
              customer: item.meta.sender.email,
              status: {
                keyTab: "5",
                display: "キャンセル",
                style: {
                  backgroundColor: "red",
                  color: "white",
                },
              },
              payment_type: "bmg",
              total_price: 169,
              message: item.messages[item.messages.length - 1]?.content,
              type:
                item.messages[item.messages.length - 1]?.sender.type ===
                "contact"
                  ? item.messages[item.messages.length - 1]?.created_at <
                    item.agent_last_seen_at
                    ? "old"
                    : "new"
                  : "old",
            }))
            setDataTable({
              data: newData,
              total: data.total,
            })
            setLoading(false)
          })
        }
      }
    })
  }, [page])

  // webSocket
  const webSocket = useRef(null)

  useEffect(() => {
    if (myData.pubsub_token) {
      webSocket.current = new WebSocket(
        process.env.GATSBY_NEXT_PUBLIC_URL_WEBSOCKET
      )
      webSocket.current.onopen = (e) => {
        // console.log("onopen:", e)
        webSocket.current.send(
          JSON.stringify({
            command: "subscribe",
            identifier:
              '{"channel":"RoomChannel","pubsub_token":"' +
              myData.pubsub_token +
              '"}',
          })
        )
      }

      webSocket.current.onclose = (e) => {
        console.log("closed:", e)
      }
    }

    return () => {
      if (myData.pubsub_token) {
        webSocket.current.close()
      }
    }
  }, [myData.pubsub_token])

  useEffect(() => {
    if (myData.pubsub_token) {
      webSocket.current.onmessage = (e) => {
        const json = JSON.parse(e.data)
        // console.log("Message:", json)
        if (json.type === "welcome") {
          // lets ignore the welcome
        } else if (json.type === "ping") {
          // lets ignore the pings
        } else if (json.type === "confirm_subscription") {
          // lets ignore the confirmation
        } else if (json.message.event === "message.created") {
          const dataMessage = json.message.data
          console.log("here comes message", dataMessage)
          setDataTable((prev) => {
            console.log(prev)
            if (
              prev.data?.some(
                (data) => data.order_id === dataMessage.conversation_id
              )
            ) {
              let myIndex
              const newData = prev.data?.map((data, index) => {
                if (
                  data.order_id === dataMessage.conversation_id &&
                  data.customer === dataMessage.sender.email
                ) {
                  myIndex = index
                  return {
                    ...data,
                    message: dataMessage.content,
                    type: "new",
                  }
                } else {
                  return data
                }
              })
              const temp = newData[0]
              newData[0] = newData[myIndex]
              newData[myIndex] = temp
              return {
                data: newData,
                total: prev.total,
              }
            } else {
              return {
                data: [
                  {
                    ...myData,
                    key: dataMessage.conversation_id,
                    order_id: dataMessage.conversation_id,
                    created_at: japaneseDateConvert(
                      dataMessage.created_at * 1000
                    ),
                    customer: dataMessage.sender.email,
                    status: {
                      keyTab: "5",
                      display: "キャンセル",
                      style: {
                        backgroundColor: "red",
                        color: "white",
                      },
                    },
                    payment_type: "bmg",
                    total_price: 169,
                    message: dataMessage.content,
                    type: "new",
                  },
                  ...prev.data,
                ],
                total: prev.total + 1,
              }
            }
          })

          console.log(dataTable)
        } else if (json.message.event === "conversation.created") {
          // alert(json.message.data);
        } else {
          // console.log("Hmm..., I've never seen JSON like this: ", json)
        }
      }
    }
  }, [myData.pubsub_token])

  return (
    <Table
      locale={{ emptyText: "データなし" }}
      style={{ cursor: "pointer" }}
      columns={columns}
      scroll={{ x: 1200, y: 500 }}
      loading={loading}
      dataSource={dataTable.data}
      onRow={(record, rowIndex) => {
        return {
          onClick: (e) => {
            console.log(record)
            navigate(`detail-order/${record?.order_id}`, {
              state: { ...record },
            })
          },
        }
      }}
      pagination={{
        total: dataTable.total,
        itemRender: itemRender,
        showSizeChanger: false,
        pageSize: pageSize,
        showTotal: (total, range) => (
          <div>
            {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
            {total}件)
          </div>
        ),
        onChange: (page, pageSize) => {
          console.log(page, pageSize)
          setPage((prev) => ({
            ...prev,
            offset: page - 1,
          }))
        },
      }}
    />
  )
}

export default DataTable
